"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CareTypeEnum;
(function (CareTypeEnum) {
    CareTypeEnum["All"] = "All";
    CareTypeEnum["ConvertedToPermanent"] = "Converted To Permanent";
    CareTypeEnum["Deceased"] = "Deceased";
    CareTypeEnum["Permanent"] = "Permanent";
    CareTypeEnum["Respite"] = "Respite";
})(CareTypeEnum = exports.CareTypeEnum || (exports.CareTypeEnum = {}));
