"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var MovementEnum;
(function (MovementEnum) {
    MovementEnum["All"] = "All";
    MovementEnum["AdmissionOrDeparture"] = "Admission/Departure";
    MovementEnum["EmergencyLeave"] = "Emergency Leave";
    MovementEnum["HospitalLeave"] = "Hospital Leave";
    MovementEnum["SocialLeave"] = "Social Leave";
    MovementEnum["TransitionCareLeave"] = "Transition Care Leave";
})(MovementEnum = exports.MovementEnum || (exports.MovementEnum = {}));
